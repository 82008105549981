@import "../../assets/scss/variables.scss";

.form {
    margin: 0 auto 3rem;
    & > span {
        display: block;
        text-align: left;
    }

    .inputWrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        padding: 1rem 0;
        @media (501px > width) {
            padding: .3rem 0;
        }
        label {
            color: $--accentColor;
            font-size: .7rem;
            opacity: 1;
        }
        textarea {
            resize: vertical;
        }
        input,
        input:focus-visible,
        textarea {
            padding: 10px 10px;
            outline: none;
            background-color: transparent;
            border: none;
            color: $--text-color;
            @include border-bottom;
        }
        input:valid,
        textarea:valid {
            border-color: hsla(120, 76%, 50%, 0.295);
        }
    }
    button {
        display: block;
        border: none;
        width: 100%;
        margin: 1rem auto 0;
        padding: 10px 20px;
        background-color: $--accentColor;
        color: $--text-color;
    }
}