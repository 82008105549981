@import "../../assets/scss/variables.scss";

.hero_wrapper {

    display: grid;
    grid-template-rows: 4fr 2fr;
    width: min(100% - 20px, 1024px);
    margin-inline: auto;
    @media (500px > width) {
        grid-template-rows: 3fr 1fr;
    }
    & .blinks {
        @include blink_animation;
        color: $--accentColor;
        text-transform: lowercase;
    }

    & .npm {
        color: $--accentColor;
        text-transform: lowercase;
        font-size: 50%;
    }

    & .hero_content {
        display: grid;
        gap: 20px;
        grid-template-columns: 5fr 3fr;
        align-items: end;

        @media (500px > width) {
            grid-template-columns: 10fr;
            align-items: center;
            gap: 0;
        }

        & .hero_main {
            align-self: end;
            justify-self: end;
            @media (500px > width) {
                align-self: center;
                justify-self: center;
            } 
            & > h3 {
                font-size: 40px;
                font-family: "NAMU1400",  sans-serif;
                font-weight: 900;
                text-align: right;
                margin: .5rem 0;
                @media (500px > width) {
                    text-align: center;
                }
            }
            & > .header1 {
                font-size: 20px;
                text-align: left;
                margin: .5rem 0;

                @media (500px > width) {
                    font-size: 16px;

                    text-align: center;
                    margin: .5rem 0;
                }

                .emoji {
                    animation: sayHi 3s ease-in-out infinite;
                    transform-origin: bottom right;
                    display: inline-block;  
                    margin-left: -1.3em;
                  }
                  
                  @keyframes sayHi {
                    0% {
                      transform: rotate(-3deg) scaleX(-1);
                    }
                    10% {
                      transform: rotate(5deg) scaleX(-1);
                    }
                    20% {
                      transform: rotate(-3deg) scaleX(-1);
                    }
                    30% {
                      transform: rotate(5deg) scaleX(-1);
                    }
                    40% {
                      transform: rotate(-3deg) scaleX(-1);
                    }
                    100% {
                        transform: rotate(-3deg) scaleX(-1);
                      }
                  }
            }
            
            & > h4 {
                font-size: 20px;
                text-align: right;
                margin: .5rem 0;
                @media (500px > width) {
                    font-size: 16px;
                    text-align: center;
                    margin: .5rem 0;
                }
            }
        }

        & .photo_hero {
            width: 200px;
            border-bottom: 16px solid $--accentColor;
            margin-bottom: 9px;
            @media (500px > width) {
                display: none;
            }

            & > img {
                max-width: 200px;
                height: auto;
                margin-bottom: -3px;
                user-select: none;
                object-position: bottom center;
            }
        }
    }

    & .hero_links {
        width: 400px;
        margin-inline: auto;

        @media (500px > width) {
            width: 100%;
        }

        &>ul {
            margin-top: 2rem;
            display: flex;
            flex-direction: row;
            gap: 20px;
            list-style: none;

            @media (500px > width) {
                flex-direction: column-reverse;
                align-content: space-around;
                justify-content: center;
                align-items: center;
            }

            &>li {
                @media (500px > width) {

                    &:nth-child(2) {
                        display: none;
                    }
                }

                &>a {
                    display: flex;
                    gap: 10px;

                    @media (500px > width) {
                        opacity: 1 !important;

                    }

                }

                &:nth-child(1) {
                    svg {
                        rotate: -90deg;
                        @include transition300;
                    }

                    &:hover {
                        svg {
                            rotate: -45deg
                        }
                    }
                }

                &:nth-child(3) {
                    svg {
                        rotate: 0;
                        @include transition300;
                    }

                    &:hover {
                        svg {
                            rotate: -45deg
                        }
                    }
                }

            }
        }
    }
}

.highlight {
    color: $--accentColor;
}