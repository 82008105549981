@import "./assets/scss/variables.scss";


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "NAMU-Pro";
  min-height: 100vh;
  overflow: hidden;
  width: 100%;
  color: $--text-color;
}

::-webkit-scrollbar {
  width: 5px;
  visibility: hidden;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: $--background-color;
}
/* Handler */
::-webkit-scrollbar-thumb {
  
  max-height: 20px;
  background-color:$--accentColor;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: $--accentColor;
}
.App {
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100vH;
  background-color: $--background-color;
  color: $--text-color;
  background-image:
    linear-gradient(45deg, transparent 30%, #191919 70%),
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%239c92ac62' fill-opacity='0.05' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"),
    linear-gradient(to right top,
      #343a40de,
      #2b2c3186,
      #211f229f,
      #151314a2,
      #0000007e);
      @media (500px < width) {
cursor: none;
      }
}

a {
  color: $--text-color;
  text-decoration: none;
  @include transition300
}


@media (500px < width) {
  .App:hover>.cursorOne {
    position: absolute;
    background-image: radial-gradient($--accentColor 0%, transparent 20%);
    width: 50px;
    height: 50px;
    pointer-events: none;
    z-index: 999999;
  }

  .App:hover>.cursorTwo {
    position: absolute;
    background-image: radial-gradient($--accentColor 0%, transparent 20%);
    width: 50px;
    height: 50px;
    pointer-events: none;
    z-index: 999999;
  }

  .App:hover>.cursorThree {
    position: absolute;
    background-image: radial-gradient($--accentColor 0%, transparent 20%);
    width: 50px;
    height: 50px;
    pointer-events: none;
    z-index: 999999;
  }
}