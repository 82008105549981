@import "../../assets/scss/variables.scss";
$primary-color: #5c6ac4;
$secondary-color: #f2f2f2;

.about_wrapper {
    width: min(800px, 100% - 20px);
    margin-inline: auto;
    padding: 0 0 8rem;

    .lang_selector {
        display: flex;
        justify-content: flex-end;

        .btn_lang {
            background-color: transparent;
            color: $--text-color;
            border: none;
            outline: none;
            padding: 0.5rem;
            margin-right: 1rem;
            cursor: pointer;

            &:hover {
                color: darken($--text-color, 20%);
            }
        }

        .unselected {
            &::before {
                content: "> ";
                color: transparent;
            }
        }

        .selected {
            &::before {
                content: "> ";
                color: $--accentColor;
            }
        }
    }

    .highlight {
        color: $--accentColor;
    }

    &>h3 {
        font-size: 36px;
        margin-bottom: 1rem;

        &>span.highlight {
            color: $--accentColor;
        }
    }

    &>p {
        margin-bottom: 1rem;
        font-size: 20px;

        &>span.highlight {
            color: $--accentColor;
        }
    }

}