@import "../../assets/scss/variables.scss";

.header {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 2fr 5fr;

    @media (501px < width < 768px) {
        grid-template-columns: 2fr 5fr;
    }

    &>.hamburger {
        background-color: transparent;
        border: none;
        outline: none;
        position: absolute;
        top: $--1fr-padding * 2;
        right: $--1fr-padding * 2;
        width: 30px;
        height: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
        z-index: 999999;
        cursor: pointer;

        @media (500px < width) {
            display: none;
        }

        &>span {
            height: 3px;
            width: 3px;
            background-color: $--text-color;
            border-radius: 3px;
            @include transition300;

        }

        &:hover span {
            &:nth-child(1) {
                width: 60%;
            }

            &:nth-child(2) {
                width: 100%;
            }

            &:nth-child(3) {
                width: 80%;
            }
        }

        &.open {
            &>span {
                background-color: $--text-color;

                &:nth-child(1) {
                    width: 100%;
                    rotate: -45deg;
                    translate: 2px 9.5px;
                }

                &:nth-child(2) {
                    translate: -30px;
                    width: 0;
                    opacity: 0;
                }

                &:nth-child(3) {
                    width: 100%;
                    rotate: 45deg;
                    translate: 2px -5.5px;
                }
            }
        }
    }

    &>.logo {

        justify-content: flex-start;
        padding: $--1fr-padding calc($--1fr-padding*2);
        z-index: 999999;
        &>svg {
            position: absolute;
            top: $--1fr-padding;
        }
    }

    &>.social {

        @include flex_row_center_center;
        position: relative;
        align-items: center;
        padding: $--1fr-padding;
        @media (768px > width) {
            display: none;
        }
        @media (500px > width) {
            position: absolute;
            padding:  2rem;
            opacity: 1;
            top: -150%;
            left: 50%;
            @include transition300;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 20px;
            z-index: 9999;
            translate: -50% 0%;
            &>a {
                // margin-inline: auto;
                &:hover {
                    color: $--background-color;
                }
            }
            &.open {
                top: 0%;
                
            }
        }
    }

    &>.navigation {

        @include flex_row_end_start;
        justify-content: flex-end;
        align-items: center;
        padding-right: calc($--1fr-padding * 2);
        gap: 30px;
        @include transition300;

        .link {
            color: $--text-color;
            text-transform: none;
            text-decoration: none;
            @include transition300;

            &::before {
                content: "_ ";
                color: $--white-accent;
                @include transition300
            }

            &:hover {
                color: $--accentColor;
                @media (501px > width) {
                    &::before {
                        content: "> ";
                        color: $--background-color
                    }
                }
            }

            &.active {
                color: $--white-accent;
                opacity: 1 !important;
                transform: translate3d(0, 0, 0) !important;

                &::before {
                    content: "> ";
                    color: $--accentColor;
                }
                @media (501px > width) {
                    &::before {
                        content: "> ";
                        color: $--background-color
                    }
                }
            }
        }

        @media (501px > width) {
            position: absolute;
            padding:  0 2rem;
            opacity: 1;
            top: -150%;
            left: 50%;
            @include transition300;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 10px;
            height: 40vH;
            width: 60%;
            z-index: 999;
            background-color: $--accentColor;
            translate: -50% 0%;
            
            &.open {
                top: 0%;
                &>a {
                    margin-inline: auto;
                    &:hover {
                        color: $--background-color;
                    }
                }
            }
        }
    }
}