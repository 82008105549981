@import "../../assets/scss/variables.scss";

.project {
    // scroll-snap-align: start;
    height: 75vh;
    scroll-behavior: smooth;

    @media (500px > width) { 
        height: 90vh;
        scroll-behavior: smooth;
       }
       &>.line {
        width: 60%;
        margin-inline: auto;
        height: 2px;
        background-color: $--accentColor;
        margin: 1rem 0
       }
       &>.links {
        margin-inline: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        &>a {
            position: relative;
            &:after {
                position: absolute;
                height: 0px;
                width: 100%;
                bottom: -20px;
                left: 0;
                background-color: $--accentColor;
                content: "";   
                opacity: 0;
                @include transition300
            }
        }
        &>a:hover {
            &:after {
                opacity: 1;
                height: 2px;
                bottom: -5px;
            }
            
        }
       }
    &>p.desc {
        white-space: pre-line;
    }

    &>.project_header {
        display: flex;
        flex-direction: row;
        position: relative;
        isolation: isolate;
        margin: 1rem 0 5rem;
        @media (500px > width) { 
            margin: 1rem 0 1rem;
           }

        img {
            margin-inline: auto;
            scale: 1;
            @include transition300;
            z-index: 1;
            transform-origin: top;
            border-radius: 5px;
            @media (500px > width) { 
                width: min(100%, 400px - 10px);
               }
        }

        h2 {

            position: absolute;
            top: 40px;
            left: 0;
            height: 60px;
            background-color: $--accentColor;
            padding: 1rem;
            @include transition300;
            z-index: 10;
            @media (500px > width) { 
                top: -60px;
               }
        }

        &:hover {
            h2 {
                rotate: -90deg;
                top: 120px;
                left: -80px;
                @media (500px > width) { 
                    rotate: 0deg;
                    top: -60px;
                    left: 0;
                   }
            }

            img {
                scale: 1.2;
                @media (500px > width) { 
                    scale: 1;
                   }
            }
        }
    }
}