@import "../../assets/scss/variables.scss";
.icon{
    width: 20px;
    height: 20px;
}

// .icon:hover {
//     fill: currentColor;
//     stroke: currentColor;
// }

.arrow_icon, .github_icon, .linkedin_icon, .ya_logo_icon, .email_icon, .cv_icon, .email_icon {
    user-select: none;
    transition: all 0.2s ease-in-out;
}

.ya_logo_icon {
    width: 40px;
    height: 40px;
}
.email_icon:hover, .ya_logo_icon:hover, .cv_icon:hover {
    stroke: $--accentColor;
    filter:drop-shadow(0 0 0.5rem $--text-color);
    @media (501px > width) {
        stroke: $--background-color;
    }
}

.github_icon:hover, 
.linkedin_icon:hover
 {
    fill: $--accentColor;
    filter:drop-shadow(0 0 0.5rem $--text-color);
    @media (501px > width) {
        fill: $--background-color;
    }
}