@import "../../assets/scss/variables.scss";


.contact_wrapper {
    width: min(100% - calc(4 * $--1fr-padding), 1024px);
    min-height: 80vH;
    margin-inline: auto;
    margin-top: 80px;
    place-content: center;
    display: grid;
    grid-template-columns: 5fr 3fr;
    gap: 80px;

    @media (width < 768px) {
        gap: 20px;
    }
    @media (501px > width) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 20px;
        align-items: center;
        margin-bottom: 5rem;
    }
    .content {

        .info {
            display: flex;
            flex-direction: column;
            gap: 20px;
            h3 {
                font-size: 36px;
                text-align: right;
                color: $--accentColor;
            }
            h4 {
                text-align: right;
                font-size: 30px;
                margin-bottom: .6rem;
            }
            
        }
        .links_wrapper {
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 20px;
        }
    }
}
.npm {
    color: $--accentColor;
    text-transform: lowercase;
    font-size: 50%;
}